import { Button } from 'components/common/atoms';
import { MainMenu } from 'components/common/organisms';
import Navbar from 'components/common/organisms/Navbar';

import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

const PageNotFound = () => {
  const router = useRouter();

  const onClickRedirect = () => {
    router.push('/');
  };
  return (
    <>
      <NextSeo title="Oops, something went wrong!" description="" />
      <MainMenu />
      <div className="page-section  flex flex-col min-h-screen">
        <Navbar hideTitleOnMobile={true} />
        <div className="w-full text-center flex items-center justify-center flex-1">
          <div className="space-y-5">
            <div className="flex items-center m-auto ">
              <img src="/images/404.svg" loading="lazy" className="block m-auto lg:max-w-1/2vw" />
            </div>
            <h2 className="text-black text-3xl font-semibold pt-7">Oops, something went wrong!</h2>
            <p className="pb-5">Looks like this page doesn’t exist, but don’t let that stop you.</p>
            <Button fill="none" onClick={onClickRedirect} className="m-auto">
              <svg width="22" height="21" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.5 11L7.33 11L10.91 7.41L9.5 6L3.5 12L9.5 18L10.91 16.59L7.33 13L21.5 13V11Z"
                  fill="#F53D3F"
                />
              </svg>
              Take me to the homepage
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
